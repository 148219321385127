import { AzureMap, AzureMapDataSourceProvider, AzureMapFeature, AzureMapLayerProvider, AzureMapPopup, AzureMapsProvider, ControlOptions, IAzureMapControls, IAzureMapOptions } from 'react-azure-maps';
import { useStyles } from "./MapLocation.styles";
import { address } from "../constants/Address";
import { AuthenticationType, data, MapMouseEvent, PopupOptions } from 'azure-maps-control'; // Ensure proper imports
import { useMemo, useState } from 'react';
import 'azure-maps-control/dist/atlas.min.css';
import Blade from '../blade/Blade';
import { useTranslation } from "react-i18next";


const AzureMapLocation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const longitude = address.longitude;
    const latitude = address.latitude;
    const [popupOptions, setPopupOptions] = useState<PopupOptions>({});
    const rendId = Math.random();

    const point = useMemo(() => (new data.Position(longitude, latitude)), [longitude, latitude]);

    const mapOptions: IAzureMapOptions = useMemo(() => {
        return {
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: process.env.REACT_APP_AZURE_MAP_ACCESS_TOKEN as string,
            },
            center: [address.longitude, address.latitude],
            zoom: 15,
            view: 'Auto',
            showFeedbackLink: false,
            showLogo: false,
        };
    }, []);


    const memoizedMarkerRender = useMemo(
        () => (
            <AzureMapFeature
                key={rendId}
                id={rendId.toString()}
                type="Point"
                coordinate={point}
                properties={{
                    title: 'Pin',
                    icon: 'pin-round-blue',
                }}
            />),
        [rendId, point],
    );

    const controls: IAzureMapControls[] = [
        {
            controlName: 'StyleControl',
            controlOptions: { mapStyles: 'all' },
            options: { position: 'top-right' } as ControlOptions,
        },
        {
            controlName: 'ZoomControl',
            options: { position: 'top-right' } as ControlOptions,
        },
        {
            controlName: 'CompassControl',
            controlOptions: { rotationDegreesDelta: 10, style: 'dark' },
            options: { position: 'bottom-right' } as ControlOptions,
        },
        {
            controlName: 'PitchControl',
            controlOptions: { pitchDegreesDelta: 5, style: 'dark' },
            options: { position: 'bottom-right' } as ControlOptions,
        },
        {
            controlName: 'TrafficControl',
            controlOptions: { incidents: true },
            options: { position: 'top-left' } as ControlOptions,
        },
        {
            controlName: 'TrafficLegendControl',
            controlOptions: {},
            options: { position: 'bottom-left' } as ControlOptions,
        },
    ];


    return <div className={`${classes.mapContainer} ${classes.azureMapContainer}`}>
        <AzureMapsProvider >
            <AzureMap options={mapOptions} controls={controls}>
                <AzureMapDataSourceProvider id={'data source provider'}>
                    <AzureMapDataSourceProvider id={'MultiplePoint AzureMapDataSourceProvider'}>
                        <AzureMapLayerProvider
                            id={'MultiplePoint AzureMapLayerProvider'}
                            options={{
                                iconOptions: {
                                    image: 'pin-blue',
                                },
                            }}
                            events={{
                                mousemove: (e: MapMouseEvent) => {
                                    if (e.shapes && e.shapes.length > 0) {
                                        // Set popup options
                                        setPopupOptions({
                                            ...popupOptions,
                                            position: point,
                                            pixelOffset: [0, -18],
                                        });

                                    }
                                },
                            }}
                            type="SymbolLayer"
                        />
                        {memoizedMarkerRender}
                    </AzureMapDataSourceProvider>
                </AzureMapDataSourceProvider>
                <AzureMapPopup
                    isVisible={true}
                    options={popupOptions}
                    popupContent={<Blade name={t('contact_address')} value={address.value} />}
                />
            </AzureMap>
        </AzureMapsProvider>

    </div>
};
export default AzureMapLocation;