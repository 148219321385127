import {styles} from './Hero.styles';

export type heroProps = {
  isDefault: boolean
}

const Hero = (props: heroProps) => (
          <div style={styles(props.isDefault).heroImage} />
    );
  
  export default Hero;