import { useState } from 'react';
import { useTheme   } from '@material-ui/core/styles';
import { Box, Collapse, Menu, MenuItem} from '@material-ui/core';
import { AppBar, Button,Divider, Drawer, IconButton, 
  List ,ListItem, ListItemText, useMediaQuery , Toolbar} from '@material-ui/core';
  import { Link, useNavigate } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {styles, useStyles} from './Header.styles';
import { navItems } from '../constants/NavItems';
import React from 'react';
import logo from "../header/logo.svg"
import { useTranslation } from 'react-i18next';
import { LanguageSelect } from '../LanguageSelector/LanguageSelect';
import { government } from '../constants/PageNames';

const Header = () =>{
  
  const { i18n ,t } = useTranslation();
  const pageDirection = i18n.dir();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const handleMenuToggle = () => setMenuOpen(!menuOpen);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleLogoClick = () => window.location.href = '/';
  const handleMenuClose = () =>  setAnchorEl(null);
  const handleNav = (path: string) => {navigate(path); handleDrawerToggle()}
  

  const isEnglish = i18n.language === 'en';
  const IsShown = (item: string) => isEnglish || (!isEnglish && item !== government);
  
  const renderDrawer = () => {
      return (
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }} >
          <List>
            {
              navItems.map(({item, path, subNavItems}, index) => (
                (subNavItems.length === 0)?(
                  IsShown(item) && (
                    <ListItem key={index} button  onClick={() => { handleNav(path) }}>
                      <ListItemText primary={t(item)} />
                    </ListItem>
                  )
                ):(
                  <>
                    <ListItem key={index} button >
                      <ListItemText primary={t(item)} onClick={()=>{handleMenuToggle()}}/>
                      {menuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding >
                        {
                          subNavItems.map(({item, path},index) =>(
                            <ListItem key={index} button onClick={()=>{handleNav(path)}} 
                                      href={path} className={classes.nested}>
                              <ListItemText primary={t(item)} />
                            </ListItem>
                          ))
                        }
                      </List>
                    </Collapse>
                  </>
                )
              ))
            }
          </List>
          <Divider />
        </Drawer>
         );
      };

      return (
      <Box>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <img src={logo} style={styles(pageDirection).logo} alt="logo" onClick={handleLogoClick}/>
            {isSmallScreen ? (
              <>
                <LanguageSelect/>
                <IconButton edge="end" color="inherit" onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
                {renderDrawer()}
              </>
            ) : (
              <>
                {
                  navItems.map(({item, path, subNavItems},index) => (
                    (subNavItems.length === 0)?(
                      IsShown(item) && (
                        <Button href={path} key={index} className={classes.link}>
                          {t(item)}
                        </Button>
                      )
                      ):
                      (
                        <>
                          <Button  key={index} color="inherit"
                            aria-haspopup="true"
                            className={classes.link}
                            onClick={handleMenuClick}
                            >
                            {t(item)}
                          </Button>
                          <Menu 
                              id={`${item}-menu`}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => setAnchorEl(null)}
                              className={classes.subNavMenu}
                          >
                              
                            {
                              subNavItems.map(({item, path},index) =>(
                                <MenuItem key={index} component={Link} to= {path} onClick={handleMenuClose}>
                                  {t(item)}
                                </MenuItem>
                              ))
                            }

                          </Menu>
                        </>
                      )
                    ))
                }
                
                <LanguageSelect/>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      );
  };
  export default Header;