
import { Route, Routes } from "react-router-dom";
import Header from './core/header/Header';
import HomePage from "./features/home/Home";
import PageNotFound from "./features/404/PageNotFound";
import Contact from "./features/contact/Contact";
import Development from "./features/services/development/Development";
import Government from "./features/government/Government";
import About from "./features/about/About";
import Management from "./features/services/management/Management";
import Footer from "./core/footer/Footer";
import Sitemap from "./features/sitemap/Sitemap";
import { aboutPath, contactPath, developmentPath, governmentPath, homePath, managementPath, notFoundPath, sitemapPath } from "./core/constants/Paths";
import { PageViewTracker } from "./core/utilities/analytics/Functions";
import { StructuredData } from "./core/utilities/StructuredData";
import { useTranslation } from "react-i18next";
import Banner from "./core/banner/Banner";

const App = () =>{

  PageViewTracker(window.location.pathname + window.location.search);
  
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  return (
      <>
        <StructuredData/>
        {isEnglish && <Banner/>}
        <Header />
        <Routes >
          <Route path={homePath} element={<HomePage />} />
          <Route path={aboutPath} element={<About />} />
          <Route path={developmentPath} element={<Development />} />
          <Route path={managementPath} element={<Management />} />
          <Route path={governmentPath} element={isEnglish ? <Government />: <PageNotFound /> }/>
          <Route path={contactPath} element={<Contact />} />
          <Route path={sitemapPath} element={<Sitemap />} />
          <Route path={notFoundPath} element={<PageNotFound />} />
        </Routes >
        <Footer />
      </>
  ) ;

}

export default App;
