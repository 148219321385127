import { useStyles } from './TestimonialCard.styles';
import { Card, CardContent, Typography, Avatar } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import { clientTestimonial } from '../../../../core/utilities/ClientTestimonial';


const TestimonialCard = (props: clientTestimonial) => {
    
    const classes = useStyles();

      return (
        <Card className={classes.card}>
          <CardContent>
          <div className={classes.quoteIcon}>
            <FormatQuoteIcon />
          </div>
            <Typography variant="body2" align="center">
              {props.content}
            </Typography>
          </CardContent>
            <Avatar className={classes.avatar} src={props.avatar} alt="User" />
            <Typography variant="h6" align="center" gutterBottom>
              {props.name}
            </Typography>
            <Typography variant="subtitle2" align="center" gutterBottom>
              {props.title}
            </Typography>
        </Card>
      );
      };
  
  export default TestimonialCard;