import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 0),
    backgroundColor: '#f2f2f2',
    borderTop: `1px solid #e1dfdd`,
    marginTop: theme.spacing(3),
    color: `#616161`,
  },
  footerText: {
    textAlign: 'center',
  },
  leftAlignedText: {
    textAlign: 'left', 
  },
}));
