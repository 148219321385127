
import { makeStyles } from '@material-ui/core/styles';
import pageBackground from  '../../core/pictures/backgrounds/goverment.jpg';

export const useStyles = makeStyles((theme) => ({
  pageContent: {
    paddingTop: "4rem",
    paddingBottom: "6rem",
    color:"#0000008a",
    },
   container: {
    padding: "50px 80px",
    backgroundImage: `linear-gradient(to right, rgba(245,245,245,0.85), rgba(245,245,245,0.85)),url(${pageBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  heading: {
    marginBottom: theme.spacing(3),
  },
  subHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  downloadLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  paragraph: {
    marginBottom: theme.spacing(1),
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
}));