import { managementPath } from "../../../core/constants/Paths";
import { itManagementPage } from "../../../core/constants/SearchEngine";
import { managementItems } from "../../../core/constants/services/ManagementItems";
import { PageViewTracker } from "../../../core/utilities/analytics/Functions";
import Services from "../Services";


const Management = () =>{
  
    PageViewTracker(managementPath);

    return <Services page={itManagementPage} serviceCardList={managementItems} />
} 
  
export default Management;