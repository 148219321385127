import {styles} from './Message.styles';

export type messageProps = {
    header: string,
    description?: string,
  }

const Message = (props: messageProps) => (
     <div style={styles.container}>
        <h2 style={styles.header}> {props.header}</h2>
        <p style={styles.message}>{props.description}</p>
     </div>
    );
  
  export default Message;