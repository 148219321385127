
import alaskaAirlineslogo from "../pictures/logos/as-logo.svg";
import asmllogo from "../pictures/logos/asml-logo.svg";
import microsoftlogo from "../pictures/logos/microsoft-logo.svg";
import pselogo from "../pictures/logos/pse-logo.png";
import visalogo from "../pictures/logos/visa-logo.svg";
import wizardsOfTheCoastlogo from "../pictures/logos/wizards-of-the-coast.svg";
import { clientLogo, clientLogoList } from "../utilities/ClientLogo";

export const alaskaAirlines = "Alaska Airlines";
export const asml = "Asml";
export const microsoft = "Microsoft";
export const pse = "Pse";
export const visa = "Visa";
export const wizardsOfTheCoast = "Wizards of the coast";

const alaskaAirlinesObj: clientLogo = { name: alaskaAirlines, path: alaskaAirlineslogo }; 
const asmlObj: clientLogo =  { name: asml, path: asmllogo };
const microsoftObj: clientLogo =  { name: microsoft, path: microsoftlogo };
const pseObj: clientLogo = { name: pse, path: pselogo };
const visaObj: clientLogo = { name: visa, path: visalogo };
const wizardsOfTheCoastObj: clientLogo = { name: wizardsOfTheCoast, path: wizardsOfTheCoastlogo }
 

export const clients: clientLogoList = { clientList: [],};

clients.clientList.push( alaskaAirlinesObj, asmlObj, microsoftObj, pseObj, visaObj, wizardsOfTheCoastObj );