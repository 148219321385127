import ClientLogo from "../clientLogo/ClientLogo";
import { clientLogoList } from "../../../../core/utilities/ClientLogo";
import { Grid } from "@material-ui/core";

const ClientLogoList = (props: clientLogoList) => (
    <Grid container spacing={2} >
      { props.clientList.map((item, index) => (
        <Grid item xs={6} sm={4} md={2} key={index}>
          <ClientLogo key={item.name} name={item.name} path={item.path}/>
        </Grid>
      ))}
    </Grid>
  );

export default ClientLogoList;