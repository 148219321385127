import { Container, Grid, Typography,Link,} from '@material-ui/core';
import { useStyles } from './Sitemap.styles';
import SearchEngineOptimization from '../../core/SearchEngineOptimization/SearchEngineOptimization';
import { sitemapPage } from '../../core/constants/SearchEngine';
import { PageViewTracker } from '../../core/utilities/analytics/Functions';
import { sitemapPath } from '../../core/constants/Paths';
import { useTranslation } from 'react-i18next';

const Sitemap = () => {
  
  PageViewTracker(sitemapPath);
  
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
    <SearchEngineOptimization title ={t(sitemapPage.title)} metaDescription = {t(sitemapPage.metaDescription)}/>
      <Grid container spacing={2} className={classes.section}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            <Link href="/">Home</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            <Link href="/about">About</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Services
          </Typography>
          <ul>
            <li className={classes.listItem}>
              <Link href="/services/it-development">IT Development</Link>
            </li>
            <li className={classes.listItem}>
              <Link href="/services/it-management">IT Management</Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            <Link href="/government">Government</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            <Link href="/contact">Contact</Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Sitemap;
