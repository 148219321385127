import { Grid } from "@material-ui/core";
import { missionHeader, missionMessage, valueHeader, valueMessage, visionHeader, visionMessage } from "../../core/constants/Identity";
import Message from "../../core/message/Message";
import { styles, useStyles } from "./About.styles";
import PictureTile from "../../core/pictureTile/PictureTile";
import { directionsTile, valuesTile } from "../../core/constants/Pictures";
import { aboutPage } from "../../core/constants/SearchEngine";
import SearchEngineOptimization from "../../core/SearchEngineOptimization/SearchEngineOptimization";
import { PageViewTracker } from "../../core/utilities/analytics/Functions";
import { aboutPath } from "../../core/constants/Paths";
import { useTranslation } from "react-i18next";


const About = () =>{
  
  PageViewTracker(aboutPath);
  
  const { t } = useTranslation();
    
  const classes = useStyles();

  const paragraphs = t(valueMessage).split('\n\n').map((paragraph, index) => (
        <p style={{ marginTop: '0.3rem' }} key={index} dangerouslySetInnerHTML={{ __html: paragraph.replace(/^\s+/, '') }} />));

  return (
        <div className={classes.root}>
            <SearchEngineOptimization title ={t(aboutPage.title)} metaDescription = {t(aboutPage.metaDescription)}/>
            <div style={{ marginTop: 10, }}>
              <Message header={t(visionHeader)} description={t(visionMessage)}/>
            </div>
             <div style={styles.middleSection}>
                <div className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <PictureTile path={directionsTile.path} altText={directionsTile.altText}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className={classes.infoContainer}>
                                <div className={classes.wrapper}>
                                    <h3 style={styles.header}> {t(missionHeader)}</h3>
                                    <p style={styles.message}>{t(missionMessage)}</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
             </div>
             <div style={{ marginTop: 30, }}>
             <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <div className={classes.infoContainer}>
                        <div className={classes.wrapper}>
                            <h3 style={styles.header}> {t(valueHeader)}</h3>
                            {
                                paragraphs
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    <PictureTile path={valuesTile.path} altText={valuesTile.altText}/>
                </Grid>
            </Grid>
             </div>
  
        </div>
    );
} ;

export default About;