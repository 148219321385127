const getStructuredData = () => {
    return {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Subtle Scale LLC",
        "image": "https://subtlescale.net/static/media/HomeHero.6bf8afa45d72c61305c4.jpeg",
        "logo": "https://subtlescale.net/static/media/logo.4e7212b288379f80db2cae53dad6756b.svg",
        "description": "Subtle Scale is a provider of custom software development services. We specialize in developing tailored software solutions to meet the unique needs of businesses across various industries. Our team of skilled developers and engineers utilize the latest technologies and best practices to deliver high-quality software that drives business growth and innovation. From web and mobile app development to enterprise software solutions, we are dedicated to delivering exceptional results that exceed our clients' expectations. Contact us today to discuss your software development needs and let us help bring your ideas to life.",
        "url": "https://subtlescale.net/",
        "telephone": "5512087400",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "16220 NE 12TH CT Suite C38",
            "addressLocality": "Bellevue",
            "postalCode": "98008",
            "addressCountry": "United States"
        },
        "potentialAction": [
          {
            "@type": "Action",
            "name": "About",
            "target": "https://subtlescale.net/about"
          },
          {
            "@type": "Action",
            "name": "IT Development",
            "target": "https://subtlescale.net/services/it-development"
          },
          {
            "@type": "Action",
            "name": "IT Management",
            "target": "https://subtlescale.net/services/it-management"
          },
          {
            "@type": "Action",
            "name": "Government",
            "target": "https://subtlescale.net/government"
          },
          {
            "@type": "Action",
            "name": "Contact",
            "target": "https://subtlescale.net/contact"
          }
        ]
    };
  };
  
  export const StructuredData = () => 
    <script type="application/ld+json">
        {JSON.stringify(getStructuredData())}
    </script>