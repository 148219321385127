import { Container, Grid } from "@material-ui/core";
import { useStyles } from "./ServiceCardList.styles";
import ServiceCard from "../serviceCard/ServiceCard";
import { Service } from "../../utilities/Service";
import { useTranslation } from "react-i18next";

export type serviceCardListProps = {
  serviceCardList: Service[],
}

const ServiceCardList = (props: serviceCardListProps) => {
  
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
    <Grid container spacing={5}>
      {props.serviceCardList.map((service, index) => ( 
          <ServiceCard key={index} 
                      header={t(service.header)} 
                      subHeader={t(service.subHeader)} 
                      description={t(service.description)}/>
      ))}
    </Grid>
  </Container>

  );
  };
  
  export default ServiceCardList;