import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({
    card: {
      maxWidth: 300,
      margin: 'auto',
      transition: 'box-shadow 0.3s',
        '&:hover': {
         boxShadow: theme.shadows[8],
        },
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: 'auto',
      marginBottom: theme.spacing(2),
    },
    quoteIcon: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
      color: theme.palette.primary.main,
      fontSize: '2rem',
    },
  }));