import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    section: {
        marginBottom: theme.spacing(2),
    },
    sectionTitle: {
        fontWeight: 'bold',
    },
    listItem: {
        marginBottom: theme.spacing(1),
    },
}));