import rameshAvatar from "../pictures/testimonials/ramesh.jpg";
import albertAvatar from "../pictures/testimonials/albert.png";
import jeffAvatar from "../pictures/testimonials/jeff.jpg";
import { clientTestimonial, clientTestimonialList } from "../utilities/ClientTestimonial";

const rameshContent= 'testimonial_ramesh';
const albertContent='testimonial_albert';
const jeffContent = 'testimonial_jeff';

export const ramesh : clientTestimonial = { name: "Ramesh M", title: "Zen3 Consulting", content: rameshContent, avatar: rameshAvatar };
export const albert: clientTestimonial = { name: "Albert B", title: "WideNet Consulting Group", content: albertContent, avatar: albertAvatar };
export const Jeff: clientTestimonial = { name: "Jeff M", title: "Up Media", content: jeffContent, avatar: jeffAvatar };

export const testimonials : clientTestimonialList = { testimonialList: [] };
 
testimonials.testimonialList.push( ramesh, albert, Jeff );

export default testimonials