import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './Banner.styles';
import { useEffect, useState } from 'react';
import { Alert, GlobalStyles } from '@mui/material';
// import CelebrationIcon from '@mui/icons-material/Celebration';
import { getFeatureFlag, getConfigurationSetting } from '../../services/appConfigService';

const Banner = () => {

    const classes = useStyles();
    const [message, setMessage] = useState('');
    //const [backgroundColor, setBackgroundColor] = useState('');
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchConfig = async () => {
          const bannerEnabled = await getFeatureFlag('bannerEnabled');
          const bannerMessage = await getConfigurationSetting('bannerMessage');
          //const bannerBackgroundColor = await getConfigurationSetting('bannerBackgroundColor');
    
          setVisible(bannerEnabled);
          setMessage(bannerMessage || 'Welcome to Our Job Listing Platform!');
          //setBackgroundColor(bannerBackgroundColor || '#e3f2fd');
        };
    
        fetchConfig();
      }, []);

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;
    return (
        <>
        <GlobalStyles
                styles={{
                    '@media (min-width: 1024px)': {

                        '.MuiAlert-action': {
                            marginLeft: '0 !important',
                        },
                        '.MuiAlert-root':{
                            padding:'0 !important',
                        }
                    }
                }}
            />
        <Alert
            // severity="success"
            className={classes.banner}
            variant="filled"
            icon={false}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                    className={classes.closeButton}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            >
            <div style={{ flexGrow: 1, color:"#041E49"}}>
            <span dangerouslySetInnerHTML={{ __html: message }} /> {/* Render HTML message */}
            </div>
        </Alert>

</>
    );
}

export default Banner;


