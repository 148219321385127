import { Grid } from "@material-ui/core";
import { useStyles } from "./Contact.styles";
import ContactInfo from "./contactInfo/ContactInfo";
import Map from "../../core/mapLocation/Map";
import { contactPage } from "../../core/constants/SearchEngine";
import SearchEngineOptimization from "../../core/SearchEngineOptimization/SearchEngineOptimization";
import { PageViewTracker } from "../../core/utilities/analytics/Functions";
import { contactPath } from "../../core/constants/Paths";
import { useTranslation } from "react-i18next";

const Contact = () => {
  
  PageViewTracker(contactPath);
  
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
    <SearchEngineOptimization title ={t(contactPage.title)} metaDescription = {t(contactPage.metaDescription)}/>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={6} md={8} lg={6}>
          <Map/>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6}>
          <ContactInfo/>
        </Grid>
      </Grid>
    </div>
  );
  };
  
  export default Contact;