import ClientLogoList from './clients/clientLogoList/ClientLogoList';
import Hero from '../../core/hero/Hero';
import Message from '../../core/message/Message';
import Statitics from './statistics/Statistics';
import { clientHeader, clientMessage, valueHeader, valueMessage, testimonialHeader } from '../../core/constants/Titles';
import TestimonialCardList from './testimonial/testimonialCardList/TestimonialCardList';
import testimonialList from '../../core/constants/Testimonials';
import { clients } from '../../core/constants/Clients';
import { styles } from './Home.styles';
import { homePage } from '../../core/constants/SearchEngine';
import SearchEngineOptimization from '../../core/SearchEngineOptimization/SearchEngineOptimization';
import { homePath } from '../../core/constants/Paths';
import { PageViewTracker } from '../../core/utilities/analytics/Functions';
import { useTranslation } from 'react-i18next';

const Home = () =>{

  PageViewTracker(homePath);
  
  const { t } = useTranslation();


    return (
      <>
        <SearchEngineOptimization title ={t(homePage.title)} metaDescription = {t(homePage.metaDescription)}/>
        <Hero isDefault={true} />
        <div style={styles.container}>
          <div style={{ marginTop: 20, }}>
            <Message header={t(valueHeader)} description={t(valueMessage)} />
            <Statitics />
          </div>
          <div style={styles.middleSection}>
            <div style={styles.container}>
              <Message header={t(clientHeader)} description={t(clientMessage)} />
              <ClientLogoList clientList={clients.clientList} />
            </div>
          </div>
          <div style={{ marginTop: 20, }}>
            <Message header={t(testimonialHeader)} />
          </div>
          <TestimonialCardList testimonialList={testimonialList.testimonialList} />
    
        </div>
      </>
    );

  }

export default Home;