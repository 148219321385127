import { home, about, services, development, management, government, contact } from "./PageNames";
import { aboutPath, contactPath, developmentPath, governmentPath, homePath, managementPath, noPath } from "./Paths";

export const navItems = 
  [
   { item: home,  path: homePath, subNavItems: []}, 
   { item: about, path: aboutPath, subNavItems: []},
   { item: services, path: noPath, 
      subNavItems: [
        { item: development, path: developmentPath},
        { item: management, path: managementPath},
      ]
   }, 
   { item: government, path: governmentPath, subNavItems: []},
   { item: contact, path: contactPath, subNavItems: []}
  ];