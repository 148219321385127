import { page } from "../utilities/Page";

export const homePage: page = {
    title: 'home_page_title',
    metaDescription: 'home_page_meta_description'
};

export const aboutPage: page = {
    title: 'about_page_title',
    metaDescription: 'about_page_meta_description'
};

export const governmentPage: page = {
    title: 'government_page_title',
    metaDescription: 'government_page_meta_description'
};

export const itDevelomentPage: page = {
    title: 'it_develoment_page_title',
    metaDescription: 'it_develoment_page_meta_description'
};

export const itManagementPage: page = {
    title: 'it_management_page_title',
    metaDescription: 'it_management_page_meta_description'
};

export const contactPage: page = {
    title: 'contact_page_title',
    metaDescription: 'contact_page_meta_description'
};

export const pageNotFoundPage: page = {
    title: 'page_not_found_page_title',
    metaDescription: 'page_not_found_page_meta_description'
};

export const sitemapPage: page = {
    title: 'sitemap_page_title',
    metaDescription: 'sitemap_page_meta_description'
};