import { useStyles } from "./PictureTile.styles";
import { Picture } from "../utilities/Picture";

const PictureTile = (props: Picture) => {
    const classes = useStyles();

    return <div className={classes.imageContainer}>
            <img src={props.path} className={classes.image} alt={props.altText} />
          </div>;
} 

export default PictureTile;