export const metrics = 
  [
   { kpi:'metric_kpi_user',  unit: 'metric_user'}, 
   { kpi:'metric_kpi_monthly_active_user',  unit: 'metric_monthly_active_user'}, 
   { kpi:'metric_kpi_countries',  unit: 'metric_countries'}, 
   { kpi:'metric_kpi_sites_scanned',  unit: 'metric_sites_scanned'}, 
   { kpi:'metric_kpi_monthly_unique_visitors_increase',  unit: 'metric_monthly_unique_visitors_increase'}, 
   { kpi:'metric_kpi_calls_volume_reduced',  unit: 'metric_calls_volume_reduced'}, 
  ];

