import { makeStyles } from '@material-ui/core/styles';

export const styles = {

      middleSection:{
          display: "flex",
          flexFlow: 'column nowrap',
          width: 'calc(100vw - 17px)',
          height: "auto",
          boxSizing: "border-box" as "border-box",
          background: "rgb(237, 235, 233)",
          marginLeft: "calc(50% - 50vw + 8.5px)",
          marginRight: "calc(50% - 50vw + 8.5px)",
          marginTop: 30,
        },
        header: {
            paddingTop: 10,
            margin: 'auto',
            color: "#000000",
            fontSize: 18,
            lineHeight: '24px',
            fontFamily: "Muli,sans-serif",
            fontWeight: "600",
            textAlign: "center" as "center",
            textRendering: "optimizeLegibility" as "optimizeLegibility"
          },
          message:{
            wordWrap: "break-word" as "break-word",
            textAlign: "center" as "center",
            color: "#000000",
            boxSizing: "border-box" as "border-box",
          }
      
  };

  export const useStyles = makeStyles((theme) => ({ 
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        width: '80%', 
        maxWidth: "1232px",
        margin: "0 auto",
        paddingRight: 15,
        paddingLeft: 15,
      },
    infoContainer: {
        height:'100%',
        display: 'flex',
        justifyContent: "center",
        flexFlow:"column wrap",
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        backgroundColor: '#edebe9',
        boxShadow: theme.shadows[18]
    },
    wrapper: {
        display: 'flex',
        flexFlow:"column wrap",
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(6),
          paddingRight: 10,
          paddingLeft: 10,
        },
    },
  }));