import { useStyles } from "./MapLocation.styles";
import addressPic from "../pictures/address.png";


const MapImage = () => {
    const classes = useStyles();
    return <div className={classes.mapContainer}>
                <figure >
                    <img src={addressPic} className={classes.mapImage} alt={"Address map"} />
                </figure>
            </div>;

} 
export default MapImage;