import { Paper, Typography } from "@material-ui/core";
import { useStyles } from "./Blade.styles";
import { ContactType } from "../utilities/ContactType";
import React from "react";

const Blade = (props: ContactType) => {
    
  const classes = useStyles();

   return (
        <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
             {props.name}
            </Typography>
            <Typography variant="body1" component="div">
                {props.value.split('<br/>').map((line, index) => (
                    <React.Fragment key={index}>
                    {line}
                    <br/>
                    </React.Fragment>
                ))}
            </Typography>
        </Paper>
    );
   };

export default Blade;