export const styles = {
  container: {
    width: '90%',
    maxWidth: "1232px",
    margin: "0 auto",
    paddingRight: 15,
    paddingLeft: 15,
  },

  middleSection: {
    display: "flex",
    flexFlow: 'column nowrap',
    width: 'calc(100vw - 17px)',
    height: "auto",
    boxSizing: "border-box" as "border-box",
    background: "rgb(237, 235, 233)",
    marginLeft: "calc(50% - 50vw + 8.5px)",
    marginRight: "calc(50% - 50vw + 8.5px)",
    marginTop: 20,
    paddingBottom: 30
  },

};