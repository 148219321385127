import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(3),
      },
      link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
  }));

  export const styles = {
    header: {
        color: "#000000",
        fontSize: "2.5rem",
        lineHeight: "3rem",
        fontFamily: "Muli,sans-serif",
        fontWeight: "600",
        marginBottom: -3,
        textAlign: "center" as "center",
        textRendering: "optimizeLegibility" as "optimizeLegibility"
      },
      message:{
        wordWrap: "break-word" as "break-word",
        textAlign: "center" as "center",
        color: "#000000",
        boxSizing: "border-box" as "border-box",
      }
      
  };