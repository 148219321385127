import { Grid } from '@material-ui/core';
import { clientTestimonialList } from '../../../../core/utilities/ClientTestimonial';
import TestimonialCard from '../testimonialCard/TestimonialCard';
import { useTranslation } from 'react-i18next';

const TestimonialCardList = (props: clientTestimonialList) => {
  
  const { t } = useTranslation();

  return (
        <Grid container spacing={2}>
          { props.testimonialList.map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TestimonialCard name= {testimonial.name} 
                              title={testimonial.title} 
                              content={t(testimonial.content)}
                              avatar={testimonial.avatar}/>
            </Grid>
          ))}
      </Grid>
    );
}

  
  export default TestimonialCardList;