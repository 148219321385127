import Message from "../../../core/message/Message";
import{metrics} from "../../../core/constants/Metrics"
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";


const Statitics = () => {
  
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            { metrics.map((item, index) => (
            <Grid item xs={6} sm={4} md={2} key={index}>
                <Message header={t(item.kpi)} description={t(item.unit)} />
            </Grid>
            ))}
        </Grid>
       );
    }

 
 export default Statitics;
