import { Service } from "../../utilities/Service";

//Web Development 
const webHeader = 'services_development_web';
const webSubHeader = 'Unleash Web Awesomeness With our Web Development Services!';
const webDescription = 'services_development_web_description';
//Application Development 
const appHeader = 'services_development_application';
const appSubHeader = 'Unlock the Power of Application Development Services!';
const appDescription = 'services_development_application_description';

//IT Architecture 
const archHeader = 'services_development_architecture';
const archSubHeader = 'IT Architect Services: Where Tech Wizards Build Magic';
const archDescription = 'services_development_architecture_description';


//Software Testing 
const testHeader = 'services_development_software_testing';
const testSubHeader = 'Software Testing Services: Where Bugs Meet Their Match with a Funny Twist!';
const testDescription = 'services_development_software_testing_description';

export const developmentItems : Service[] = 
  [
   { header: webHeader,  subHeader: webSubHeader, description: webDescription},
   { header: appHeader,  subHeader: appSubHeader, description: appDescription}, 
   { header: archHeader,  subHeader: archSubHeader, description: archDescription}, 
   { header: testHeader,  subHeader: testSubHeader, description: testDescription}, 
  ];