import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        width: '80%', 
        maxWidth: "1232px",
        margin: "0 auto",
        paddingRight: 15,
        paddingLeft: 15,
      },
  }));