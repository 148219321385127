import { Button, Container, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useStyles } from "./Government.styles";
import Hero from "../../core/hero/Hero";
import { governmentPage } from "../../core/constants/SearchEngine";
import SearchEngineOptimization from "../../core/SearchEngineOptimization/SearchEngineOptimization";
import { governmentPath } from "../../core/constants/Paths";
import { EventTracker, PageViewTracker } from "../../core/utilities/analytics/Functions";
import { useTranslation } from "react-i18next";

const Government = () => {

  PageViewTracker(governmentPath);
  EventTracker();
  
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
  const classes = useStyles();
  return (
    <>
        <SearchEngineOptimization title ={t(governmentPage.title)} metaDescription = {t(governmentPage.metaDescription)}/>
        <Hero isDefault={false}/>
        <div className={classes.buttonGroup}>
          <Button variant="contained" color="primary" className={classes.button}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={require("../../core/documents/SUBTLE_SCALE_FEDERAL SERVICES - Capabilities Statement.pdf")} 
                  >
            {isSmallScreen ? "Download Capability Statement" : "Download Federal Capability Statement"}
          </Button>
          {
            !!!isSmallScreen && (
                              <Button variant="contained" color="primary" className={classes.button} 
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={require("../../core/documents/SUBTLE_SCALE_STATE-LOCAL SERVICES - Capabilities Statement.pdf")}>
                                Download State, Local Capability Statement
                              </Button>
                            )
          }
        </div>
        <div className={classes.pageContent}>
          <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h4" component="h1" className={classes.heading}>
              Welcome to Subtle Scale's Government IT Services!
            </Typography>
            <Typography variant="body1" component="p">
            While we may not have specific past performance with state or federal governments, our extensive commercial experience positions us to deliver top-notch IT services to a wide range of government entities. Our expertise, coupled with our state certifications, ensures that we are well-equipped to meet the unique needs and standards of government operations.
            </Typography>

            <Typography variant="h6" component="h2" className={classes.subHeading}>
              Our Government IT Services cater to various government entities, including:
            </Typography> 
            <ul>
              <li className={classes.listItem}>
                <Typography variant="body1" component="span">
                    <strong>Political subdivisions:</strong> We provide IT solutions to political subdivisions such as counties, cities, school districts, public utility districts, and ports.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="span">
                  <strong>Federal governmental agencies:</strong> Our services extend to federal governmental agencies and entities, addressing their specific IT requirements.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="span">
                  <strong>Public-benefit nonprofit corporations:</strong> We support public-benefit nonprofit corporations that receive federal, state, or local funding, helping them optimize their IT infrastructure and operations.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography variant="body1" component="span">
                  <strong>Federally-recognized Indian Tribes:</strong> We assist federally-recognized Indian Tribes, offering tailored IT solutions to meet their unique needs.
                </Typography>
              </li>
            </ul>

            <Typography variant="body1" component="p" className={classes.paragraph}>
              To showcase our capabilities and expertise, we have developed both SLED (State, local and Education) and Federal Capability Statements. These documents provide detailed information about our services, qualifications, and success stories.
            </Typography>

            <Typography variant="body1" component="p" className={classes.paragraph}>
              To learn more about our capabilities and how we can support your government IT needs, please download our <a  href={require("../../core/documents/SUBTLE_SCALE_STATE-LOCAL SERVICES - Capabilities Statement.pdf")}  target="_blank" rel="noopener noreferrer" className={classes.downloadLink}>State, local and Education</a> and/or <a href={require("../../core/documents/SUBTLE_SCALE_FEDERAL SERVICES - Capabilities Statement.pdf")}  target="_blank" rel="noopener noreferrer"  className={classes.downloadLink}>Federal</a> Capability Statements.
            </Typography>

            <Typography variant="body1" component="p" className={classes.paragraph}>
               Our Capability Statements highlight our experience, certifications, and ability to deliver reliable, secure, and innovative IT services to government entities. We are committed to supporting your mission with our exceptional solutions and contributing to your IT success.
            </Typography>

            <Typography variant="body1" component="p">
              Contact us today to explore how our expertise and state certifications can bring value to your government operations. We look forward to the opportunity to work with you and contribute to your IT success.
            </Typography>

          </Container>
        </div>
    </>
  );
  };
  
  export default Government;