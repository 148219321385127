import Blade from "../../../core/blade/Blade";
import { useStyles } from "./ContactInfo.styles";
import { useTranslation } from "react-i18next";

const ContactInfo = () =>{
    
  const classes = useStyles();
  const { t } = useTranslation();

  return (
     <div className={classes.infoContainer}>
        <Blade name={t('contact_address')} value="16220 NE 12th CT C38 <br/> Bellevue WA 98008"/>
        <Blade name={t('contact_email')}  value="emoluh@subtlescale.com"/>
    </div>
   );

} ;


export default ContactInfo;