import { Container, Grid, Typography } from '@material-ui/core';
import {useStyles} from './Footer.styles';
import LinkedInButton from '../SocialMedia/LinkedIn/LinkedInButton';
const Footer = () => {
    const classes = useStyles();
  
    return (
      <footer className={classes.footer}>
      <Container maxWidth="md">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={6} className={classes.leftAlignedText}>
            <Typography variant="body2">
              © {new Date().getFullYear()} Subtle Scale
            </Typography>
          </Grid>

          {/* LinkedIn Button Component */}
          <Grid item xs={6} className={classes.leftAlignedText}>
            <LinkedInButton />
          </Grid>
        </Grid>
      </Container>
    </footer>
    );
  };
  
  export default Footer;