import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./ServiceCard.styles";

export type serviceCardProps = {
  header: string,
  subHeader: string,
  description: string,
}

const ServiceCard = (props: serviceCardProps) => {
    
  const classes = useStyles();
  return (
        <Grid item xs={12} sm={6} md={6} >
          <Card className={classes.card}>
            <CardHeader title={props.header} className={classes.cardHeader} />
            <CardContent className={classes.cardContent}>
              <Typography variant="body2"  component="p">
                {props.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

  );
  };
  
  export default ServiceCard;