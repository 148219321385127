export const visionHeader = 'vision_header';
export const visionMessage = 'vision_message';
export const missionHeader = 'mission_header';
export const missionMessage = 'mission_message';
export const valueHeader = 'values_header';
export const valueMessage = 'values_message';




