export const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'us',
  },
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  // {
  //   code: 'es', 
  //   name: 'Español', 
  //   country_code: 'es', 
  // },
  {
    code: 'de', 
    name: 'Deutsch', 
    country_code: 'de', 
  },
  {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
  },
];
