import { page } from "../utilities/Page";
import MetaDescription from "./MetaDescription";
import PageTitle from "./PageTitle";

const SearchEngineOptimization = (props: page) =>{
    MetaDescription(props.metaDescription);
    return <PageTitle title={props.title}/>
}


export default SearchEngineOptimization;