
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240; 

export const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'sticky',
    top: 0,
    right: 0,
    marginBottom: 10,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    flexGrow: 1,
    cursor: 'pointer',
    marginRight: 'auto',
    width: 'auto',
    maxWidth: 150,
    height: 50, 
  },
  link: {
    margin: theme.spacing(2),
    textDecoration: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  subNavMenu: {
    marginTop: theme.spacing(6),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const styles =  (pageDirection: string) => ({
  
  logo: {
    flexGrow: 1,
    cursor: 'pointer',
    width: 'auto',
    maxWidth: 150,
    height: 50, // 
    marginLeft: pageDirection === 'rtl' ? 'auto' : 0, // Align right for RTL direction
    marginRight:  pageDirection === 'ltr' ? 'auto' : 0, 
  }  
});