import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({ 
    mapContainer: {
        padding: theme.spacing(7),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        height: '290px',
        backgroundColor: '#edebe9',
        boxShadow: theme.shadows[18]
    },
    azureMapContainer: {
      padding: theme.spacing(2),
      height: '380px',
    },
    popupStyles: {
      padding: '20px',
      textAlign: 'center',
      color: 'black',
    },
    mapImage:{
      height: '100%', 
      width: '100%'
    }
  }));