import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({ 
    imageContainer: {
        // backgroundColor: '#f3f2f1;',
        // boxShadow: theme.shadows[18]
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }));