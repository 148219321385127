import { makeStyles } from "@material-ui/core";

  export const useStyles = makeStyles((theme) => ({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      color:"#8E9DAE",
      background: "transparent",
      boxShadow: "0px 20px 30px 0px #00000070",
      paddingLeft: 80,
      paddingRight: 80,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 10,
        paddingLeft: 10,
      },
    },
    cardHeader: {
      textAlign: 'center',
    },
    cardContent: {
      flexGrow: 1,
    },
  }));