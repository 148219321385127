import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      backgroundColor: "#f3f2f1",
      boxShadow: theme.shadows[18]
    },
  }));