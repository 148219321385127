import { Helmet } from 'react-helmet';

export type pageProps = {
    title: string,
  }

const PageTitle = (props: pageProps) => (    
    <Helmet>
        <title>{`${props.title} - Subtle Scale`}</title>
    </Helmet>
)

export default PageTitle;