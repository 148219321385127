import React from 'react';
import { IconButton, Typography, Grid } from '@material-ui/core';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useStyles } from './LinkedInButton.styles'; 
import { useTranslation } from 'react-i18next';

const LinkedInButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={1} className={classes.linkedinContainer}>
      <Grid item>
        <IconButton
          href="https://www.linkedin.com/company/subtle-scale"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.linkedinButton}
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.linkedinText}>
        {t('social_linkedin_text')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LinkedInButton;
