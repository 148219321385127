import { makeStyles } from "@material-ui/core";

import pageBackground from '../../../core/pictures/backgrounds/services.jpg' 

  export const useStyles = makeStyles((theme) => ({
    container: {
      padding: "50px 80px",
      backgroundImage: `linear-gradient(to right, rgba(245,245,245,0.85), rgba(245,245,245,0.85)),url(${pageBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "50% 50%",
      [theme.breakpoints.down('sm')]: {
        paddingRight: 20,
        paddingLeft: 20,
      },
    }
  }));