import MapImage from "./MapImage";
import AzureMapLocation from "./AzureMapLocation";


const Map = () => {
    const accessToken = process.env.REACT_APP_AZURE_MAP_ACCESS_TOKEN as string;
    
    return (IsNullOrEmpty(accessToken))?<MapImage/>:<AzureMapLocation/>

} 
export default Map;

const IsNullOrEmpty =(accessToken: string) =>  accessToken == null || accessToken === undefined || accessToken.length <= 0;