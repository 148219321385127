import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({
    pageContent: {
        paddingTop: "6rem",
        paddingBottom: "6rem",
    },
    gridContainer: {
        maxWidth: "1232px",
        margin: "0 auto",
        paddingRight: 15,
        paddingLeft: 15,
        backgroundColor: "#f3f2f1",
      },
  }));