import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: {
        translation: require("../src/core/locales/ar.json")
      },
      de: {
        translation: require("../src/core/locales/de.json")
      },
      en: {
        translation: require("../src/core/locales/en.json")
      },
      es: {
        translation: require("../src/core/locales/es.json")
      },
      fr: {
        translation: require("../src/core/locales/fr.json")
      },
    },
    //lng: 'fr',
    fallbackLng: "en",
    // Options for language detector
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false // allows for dynamic content in translations
    }
  });

export default i18n;
