import { useEffect } from "react";
import ReactGA from "./SetUp";
  
export const PageViewTracker = (path: string) => {
    useEffect(() => {
      // Track page view on component mount
      ReactGA.pageview(path);
    }, [path]);
  }
  
export const EventTracker = () => {
    ReactGA.event({
      category: 'User',
      action: 'Click',
      label: 'Button',
    });
  }