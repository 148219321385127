import { developmentPath } from "../../../core/constants/Paths";
import { itDevelomentPage } from "../../../core/constants/SearchEngine";
import { developmentItems } from "../../../core/constants/services/DevelopmentItems";
import { PageViewTracker } from "../../../core/utilities/analytics/Functions";
import Services from "../Services";


const Development = () =>{
  
    PageViewTracker(developmentPath);

    return <Services page={itDevelomentPage} serviceCardList={developmentItems} />
} 
  
export default Development;