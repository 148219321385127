import { makeStyles } from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme) => ({
    infoContainer: {
        padding: theme.spacing(6),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(4),
        },
        backgroundColor: '#edebe9',
        boxShadow: theme.shadows[18]
    },
  }));