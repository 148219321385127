
import heroImage from '../pictures/heroes/HomeHero.jpeg';
import govImage from '../pictures/heroes/GovHero1.jpg';

export const styles =  (isDefault: boolean) => ({
    heroImage: {
        textAlign: "center" as "center",
         backgroundColor: "#6a73da", 
         backgroundImage: isDefault ? `url(${heroImage})`:`url(${govImage})`,
         backgroundSize: "cover",
         backgroundPosition: "center center",
         height: 278,
         width: "calc(100vw - 17px)",
         marginRight: "calc(50% - 50vw + 8.5px)",
        },
    
  });