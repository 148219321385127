import { AppConfigurationClient } from "@azure/app-configuration";

const connectionString = process.env.REACT_APP_AZURE_APP_CONFIG_CONNECTION_STRING||"";

const client = new AppConfigurationClient(connectionString);

export const getFeatureFlag = async (featureFlagName: string) => {
  try {
    const featureFlag = await client.getConfigurationSetting({
      key: `.appconfig.featureflag/${featureFlagName}`,
    });
    return JSON.parse(featureFlag.value||"").enabled;
  } catch (error) {
    console.error(`Error fetching feature flag ${featureFlagName}:`, error);
    return false;
  }
};

export const getConfigurationSetting = async (key: string) => {
  try {
    const setting = await client.getConfigurationSetting({ key });
    return setting.value;
  } catch (error) {
    console.error(`Error fetching configuration setting ${key}:`, error);
    return null;
  }
};
