import ServiceCardList from "../../core/service/serviceCardList/ServiceCardList";
import { useStyles } from "./Services.styles";
import { Service } from "../../core/utilities/Service";
import { page } from "../../core/utilities/Page";
import SearchEngineOptimization from "../../core/SearchEngineOptimization/SearchEngineOptimization";
import { useTranslation } from "react-i18next";

export type servicesProps = {
    serviceCardList: Service[],
    page: page
  }

const Services = (props: servicesProps) => {

  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.pageContent}>
      <SearchEngineOptimization title ={t(props.page.title)} metaDescription = {t(props.page.metaDescription)}/>
        <div className={classes.gridContainer}>
            <ServiceCardList serviceCardList={props.serviceCardList}/>
        </div>
    </div>
  );
  };
  
  export default Services;