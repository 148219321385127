import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  linkedinButton: {
    color: '#0077b5', // LinkedIn blue color
    '&:hover': {
      backgroundColor: '#e1f0ff', // Light blue background on hover
    },
  },
  linkedinText: {
    color: '#616161', // Match footer text color
  },
  linkedinContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
