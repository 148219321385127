import React, { useEffect } from 'react';
import i18next from 'i18next';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Language as LanguageIcon } from '@material-ui/icons';
import { languages } from '../constants/languages';
import { useTranslation } from 'react-i18next';

const GlobeIcon = ({ width = 24, height = 24, color ="white", }) => <LanguageIcon style={{ width, height, color }} />;

export const LanguageSelect = () => {
  const currentLanguageCode = i18next.language || 'en'; 
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>  setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const changeLanguage = (code: string) => { i18next.changeLanguage(code);handleCloseMenu(); };
  
  useEffect(() => {
    document.body.dir = currentLanguage?.dir || 'ltr';
  }, [currentLanguage]);
  
  const { i18n } = useTranslation();
  const pageDirection = i18n.dir();
  
    return (
        <div>
        <IconButton aria-label="select language" onClick={handleOpenMenu}>
          <GlobeIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {languages.map(({ code, name, country_code }) => (
            <MenuItem
              key={country_code}
              onClick={() => {
                changeLanguage(code);
              }}
              selected={currentLanguageCode === code}
            >
                <span className={`flag-icon flag-icon-${country_code} mx-2`} 
                    style={{ marginRight: pageDirection === 'ltr' ? 8: 0, 
                             marginLeft: pageDirection === 'rtl' ? 8: 0 }}></span>
                {name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
}




