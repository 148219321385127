export const styles = {
    container: {
      display: "flex",
      flexFlow: "column wrap",
      //marginTop: 30
      },
    header: {
        color: "#000000",
        fontSize: "2.5rem",
        lineHeight: "3rem",
        fontFamily: "Muli,sans-serif",
        fontWeight: "600",
        marginBottom: -3,
        textAlign: "center" as "center",
        textRendering: "optimizeLegibility" as "optimizeLegibility"
      },
      message:{
        wordWrap: "break-word" as "break-word",
        textAlign: "center" as "center",
        color: "#000000",
        boxSizing: "border-box" as "border-box",
      }
      
  };