import { Service } from "../../utilities/Service";

//Project Management 
const projectHeader = 'services_management_project';
const projectSubHeader = 'Your Expert Sherpas for Project Success!';
const projectDescription = 'services_management_project_description';

//Product Management 
const productHeader = 'services_management_product';
const productSubHeader = 'The Secret Sauce for Product Success!';
const productDescription = 'services_management_product_description';

//Product Marketing Management 
const productMktHeader = 'services_management_product_mkt';
const productMktSubHeader = `Amplifying Your Product's Star Power`;
const productMktDescription = 'services_management_product_mkt_description';


//SAFe
const safeHeader = 'services_management_safe';
const safeSubHeader = 'Agile Avengers';
const safeDescription = 'services_management_safe_description';

export const managementItems : Service[] = 
  [
   { header: projectHeader,  subHeader: projectSubHeader, description: projectDescription},
   { header: productHeader,  subHeader: productSubHeader, description: productDescription}, 
   { header: productMktHeader,  subHeader: productMktSubHeader, description: productMktDescription}, 
   { header: safeHeader,  subHeader: safeSubHeader, description: safeDescription}, 
  ];