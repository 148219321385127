import { clientLogo } from "../../../../core/utilities/ClientLogo";
import { styles } from "./ClientLogo.styles";


const ClientLogo = (props: clientLogo) => (
    <figure >
      <img src={props.path} style={styles.clientLogo} alt={props.name} />
    </figure>
  );

export default ClientLogo;