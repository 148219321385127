import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
   
    banner: {
        padding: 0, 
        marginBottom: 2, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor:'#8591d5 !important', 
        backgroundColor:'#e8f0fe !important', 
    },
    closeButton: {
        position: 'absolute',
        right: 6,
        top: 6,
        marginLeft:10,
        color:"#041E49"
    },
    icon: {
        marginRight: 8, // Add some space between the icon and the message
    }
});