import { Link } from "@material-ui/core";
import { styles, useStyles } from "./PageNotFound.styles";
import { pageNotFoundPage } from "../../core/constants/SearchEngine";
import SearchEngineOptimization from "../../core/SearchEngineOptimization/SearchEngineOptimization";
import { PageViewTracker } from "../../core/utilities/analytics/Functions";
import { notFoundPath } from "../../core/constants/Paths";
import { useTranslation } from "react-i18next";
import { notFoundActionBeginning, notFoundActionEnd, notFoundActionHomepage, notFoundActionOr, notFoundActionSitemap, notFoundHeader, notFoundMessage } from "../../core/constants/NotFound";

const PageNotFound = () => {
  
    PageViewTracker(notFoundPath);
  
      const classes = useStyles();
  
      const { t } = useTranslation();
      
     return(
      <>
        <SearchEngineOptimization title ={t(pageNotFoundPage.title)} metaDescription = {t(pageNotFoundPage.metaDescription)}/>
        <div className={classes.container}>
           <h2 style={styles.header}> {t(notFoundHeader)}</h2>
          
            <p style={styles.message}> {t(notFoundMessage)} </p>
          
            <p style={styles.message}>
              {t(notFoundActionBeginning)} <Link href="/" className={classes.link}>{t(notFoundActionHomepage)}</Link> {t(notFoundActionOr)} <Link href="/sitemap" className={classes.link}>{t(notFoundActionSitemap)}</Link> {t(notFoundActionEnd)}
            </p>
        </div>
      </>
     )
    }
  
  export default PageNotFound;